<template>
  <div class="Personnel_umber">
    <!-- 输入框 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/08.png" alt="" />
        <p>人员账号</p>
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 30px"
      >
        <div>
          <el-form label-width="100px">
            <el-form-item label="账号查询：">
              <div style="display: flex">
                <el-input size="medium" v-model="name" placeholder="请输入内容"></el-input>
                <el-button
                 
                  size="medium"
                  class="button"
                  type="primary"
                  @click="inquireData"
                  style="margin-left: 20px"
                  icon="el-icon-search"
                  >查询</el-button
                >
                <el-button size="medium" plain @click="resetData" icon="el-icon-refresh-left">重置</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            size="medium"
            class="button"
            type="primary"
            icon="el-icon-plus"
            @click="newlyIncreased"
             v-if=" $store.state['user']['PermissionCodeList'].includes('insertPersonnelAccount' )"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <div class="components-form">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="mini"
      >
        <el-table-column
          prop="username"
          label="登录账号"
          align="center"
          show-overflow-tooltip
        >
          <template> </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="人员姓名"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="角色"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="{ row }">
             <el-tag style="cursor: pointer" size="small " v-if="!row.employee &&  $store.state['user']['PermissionCodeList'].includes('allotPayEntity' )" @click="NewEnterprise(row)">分配发薪企业</el-tag >
             <el-tag style="cursor: pointer;margin-left: 10px;" size="small " v-if="!row.employee &&    $store.state['user']['PermissionCodeList'].includes('updatePersonnelAccount' )" @click="editorialStaff(row)">编辑</el-tag >
             <el-tag type="danger" style="cursor: pointer;margin-left: 10px;" v-if="!row.employee &&  $store.state['user']['PermissionCodeList'].includes('deletePersonnelAccount' )" size="small " @click="deleteUser(row)">删除</el-tag >
            <!-- <div class="button_flex">
              <div
                class="button_allocation"
                v-if="!row.employee"
                @click="NewEnterprise(row)"
              >
                分配发薪企业
              </div>
              <div
                class="button_compile"
                v-if="!row.employee"
                @click="editorialStaff(row)"
              >
                编辑
              </div>
              <div
                class="button_delete"
                v-if="!row.employee"
                @click="deleteUser(row)"
              >
                删除
              </div>
            </div> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共 {{ total }} 条记录 第 1 页</p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[15, 30, 50, 100]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="dialogCloseAudit"
    >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>{{title}}</p>
        </div>
      </template>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        style="margin-left: 40px"
      >
        <el-form-item label="登录账号:" prop="username">
          <el-input
            size="medium"
            v-model.trim="form.username"
            placeholder="请输入账号"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item label="人员姓名:" prop="name">
          <el-input
            size="medium"
            v-model.trim="form.name"
            placeholder="请输入姓名"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色:" prop="roleName">
          <el-select
           size="medium"
            v-model.trim="form.roleName"
            placeholder="请选择角色"
            style="width: 70%"
            @change="changeRoleName"
          >
            <el-option
              v-for="item in characterList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <el-input
          size="medium"
            type="password"
            show-password
            v-model.trim="form.password"
            placeholder="请输入登录密码"
            style="width: 70%"
            
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="line"></div>
      <el-button
        size="medium"
        class="button"
        type="primary"
        @click="newlyData"
        :loading="loading"
        >{{title}}</el-button
      >
      <el-button @click="dialogCloseAudit" size="medium">取 消</el-button>
    </el-dialog>
    <!-- 分配发薪企业弹框 -->
    <el-dialog
      :visible.sync="dialogEnterprise"
      width="50%"
      :before-close="dialogCloseEnterprise"
    >
      <template slot="title">
        <div class="title">
          <p></p>
          <p>分配发薪企业</p>
        </div>
      </template>
      <div class="table_flex">
        <div style="width: 48%">
          <p class="table_text">所有发薪企业</p>
          <el-table
            ref="dxgrid"
            :data="EntList"
            style="width: 100%"
             height="450"
            @selection-change="handleSelectionChange"
            :row-style="setColor"
            :header-cell-style="{ background: '#6591F1', color: '#fff' }"
            size="small"
            max-height="600"
            :row-key="
              (row) => {
                return row.id;
              }
            "
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="entName"
              label="发薪企业名称"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <!-- <el-pagination
            style="margin-top: 40px"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="EntCurrentPage"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="EntCurrentPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="EntTotal"
          >
          </el-pagination> -->
        </div>
        <div style="width: 48%">
          <p class="table_text">已选择</p>
          <el-table
            :data="yxData"
            style="width: 100%"
            :header-cell-style="{ background: '#6591F1', color: '#fff' }"
            size="mini"
            max-height="600"
            height="450"
          >
            <el-table-column
              prop="entName"
              label="发薪企业名称"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="center" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button
                  type="danger"
                  plain
                  size="mini"
                  @click="handleDelete(row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="line"></div>
      <el-button
        size="medium"
        class="button"
        type="primary"
        @click="newlyEnterprise"
        :loading="loadingEnterprise"
        >添加</el-button
      >
      <el-button size="medium" @click="dialogCloseEnterprise">取 消</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSysUserList,
  saveOrUpdateSysUser,
  getSysRoleList,
  deleteSysUser,
  getSalaEntList,
  disPayEnt
} from '@/api/lssuingModule'
export default {
  data() {
    return {
      loadingTable: false,
      title:'',
      loading: false,
      tableData: [],
      pageSize: 15,
      total: 0,
      pageIndex: 1,
      name: '',
      roleId: '',
      id: '',
      dialogVisible: false,
      dialogEnterprise: false,
      characterList: [],
      // EntTotal: 0,
      // EntCurrentPage: 1,
      // EntCurrentPageSize: 10,
      EntList: [],
      alTableData: [],
      entIds: [],
      params: [],
      yxData: [],
      loadingEnterprise: false,
      EntId: '',
      matching: [],
      form: {
        username: '',
        name: '',
        roleName: '',
        password: '',
        id: '',
      },
      rules: {
        username: [
          { required: true,message: '请输入您的手机号', trigger: ['blur', 'change'] },
          {
            max: 12,
            message: '手机号长度最多不能超过11位',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change']
          }
        ],
        name: [
          { required: true, message: '请输入您的姓名', trigger: ['blur', 'change'] },
          { min: 1,max: 64, message: '登录账号长度不超过64位',trigger: ['blur', 'change']}
        ],
        roleName: [
          { required: true, message: '请输入您的角色', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, message: '请输入您的密码', trigger: ['blur', 'change']},
          { min: 1,max: 16, message: '密码长度不超过16位',trigger: ['blur', 'change']}
        ]
      }

    }
  },
  mounted() {
    this.getList()
    this.getRoleList()
    this.leftDataList ()
  },
  methods: {
    //获取数据列表
    async getList() {
      this.loadingTable = true
       setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const res = await getSysUserList({
        name: this.name,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      })
      if (res.success) {
        this.loadingTable = false
        this.tableData = res?.data?.list
        this.total = res?.data?.total
      } else {
        this.loadingTable = false
      }
    },
    // 角色列表
    async getRoleList() {
      const res = await getSysRoleList()
      if (res.success) {
        this.characterList = res?.data
      }
    },
    changeRoleName(val) {
      // console.log(val);
      this.roleId = val
      // console.log( this.roleId,' this.roleId');
      // this.form.roleName = val.name;
    },

    //点击新增弹框
    newlyIncreased() {
      this.dialogVisible = true
      this.title ='新增'
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      })
      this.form = {
        username: '',
        name: '',
        roleName: '',
        password: ''
      }
    },

    //删除人员
    deleteUser(row) {
      this.$confirm('确定要删除嘛?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteSysUser(row.id)
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 查询数据
    inquireData() {
      this.getList()
    },
    //编辑人员
    editorialStaff(row) {
      this.title = '编辑'
      let list = this.characterList.filter(item => item.name == row.roleName)
      this.roleId = list[0].id
      this.id = row.id
      this.dialogVisible = true
      this.$set(this.form, 'username', row.username)
      this.$set(this.form, 'name', row.name)
      this.$set(this.form, 'roleName', row.roleName)
      // this.$set(this.form,'roleName',row.roleName)

    },

    // 重置
    resetData() {
      this.name = ""
      this.getList()
    },

    // 当前页多少条数据
    currentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    // 第几页
    changePage(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getList()
    },
    // 新增弹框数据
    newlyData() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const res = await saveOrUpdateSysUser({
            name: this.form.name,
            password: this.form.password,
            roleId: this.roleId,
            username: this.form.username,
            id: this.id
          })
          if (res.success) {
            this.loading = false
            this.$message.success('新增成功')
            this.dialogVisible = false
            this.getList()
            this.$nextTick(() => {
              this.$refs.form.resetFields();
            })
          } else {
            // this.$message.error(res.message)
            this.loading = false
          }
        }
      })
    },


    //点击勾选
    handleSelectionChange(val) {
      this.alTableData = val
      this.singleSel()

    },
    singleSel() {
      this.yxData = [];
      this.alTableData.forEach((item, index) => {
        if (JSON.stringify(this.yxData).indexOf(JSON.stringify(item)) == -1) {
          this.yxData.push(item);
          this.params.push({
            id: item.id,
            name: item.name,
          });
        }
      });
    },
    //选中之后设置颜色
    setColor({ row, rowIndex }) {
      if (JSON.stringify(this.yxData).indexOf(JSON.stringify(row)) != -1) {
        return {
          color: "red",
        };
      }
    },

    //删除人员
    handleDelete(row) {
      this.params.splice(row.index, 1);
      this.yxData.splice(row.index, 1);
      this.$refs.dxgrid.toggleRowSelection(row);
    },

    //添加保存按钮
    async newlyEnterprise() {
      this.entIds = this.yxData.map(item => item.id)
      this.loadingEnterprise = true
      const res = await disPayEnt({
        entIds: this.entIds,
        id: this.EntId
      })
      if (res.success) {
        this.loadingEnterprise = false
        this.$message.success('添加成功')
        this.dialogEnterprise = false
        this.$refs.dxgrid.doLayout()
        this.getList()
      } else {
        this.$message.success(res.message)
        this.loadingEnterprise = false
      }

    },
    // 新增弹框取消按钮事件
    dialogCloseAudit() {
      this.dialogVisible = false
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      })
      this.form.password = ''
    },

    //新增分配人资按钮
    async NewEnterprise(row) {
      this.batchMoneyShows1()
      this.dialogEnterprise = true;
      this.matching = JSON.parse(row.entIds)
      this.EntId = row.id
       this.leftDataList()
      // const res = await getSalaEntList({
      //   pageIndex: this.EntCurrentPage,
      //   pageSize: this.EntCurrentPageSize,
      //   payChan: 1,
      // })
      // let leftData = []
      // let rightData = []

      // if (res.success) {
        //这个数据是左边得所有列表得
        // this.EntList = res?.data?.list
        // this.EntTotal = res?.data?.total
        // for (let index = 0; index < this.matching.length; index++) {
        //   for (let t = 0; t < this.EntList.length; t++) {
        //     if (this.matching[index] == this.EntList[t].id) {
        //       this.$nextTick(() => {
        //         this.$refs.dxgrid.toggleRowSelection(this.EntList[t], true)
        //       })
        //       rightData.push(this.EntList[t])
        //       break;
        //     }
        //   }
        // }
        // this.yxData = rightData
      // }
    },

    //leftData
    async leftDataList () {
       const res = await getSalaEntList()
      if (res.success) {
         this.EntList = res?.data
        //  this.EntTotal = res?.data?.total
          let leftData = []
          let rightData = []
           for (let index = 0; index < this.matching.length; index++) {
          for (let t = 0; t < this.EntList.length; t++) {
            if (this.matching[index] == this.EntList[t].id) {
              this.$nextTick(() => {
                this.$refs.dxgrid.toggleRowSelection(this.EntList[t], true)
              })
              rightData.push(this.EntList[t])
              break;
            }
          }
        }
        this.yxData = rightData
      }
    },


    batchMoneyShows1() {
      this.matching = []
      this.yxData = []
      this.entIds = []
    },
    //新增分配人资取消按钮
    dialogCloseEnterprise() {
      this.dialogEnterprise = false
      this.matching = []
      this.yxData = []
      this.entIds = []
      this.$refs.dxgrid.clearSelection();
    }
  }
}
</script>

<style lang="less" scoped>
.Personnel_umber {
  padding: 20px;
  .components-input {
    width: 100%;
    height: 169px;
    background: #ffffff;
    padding: 20px;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
    // padding: 20px;
  }
  .components-form {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .button {
    background-color: #6591f1;
  }
  .button_flex {
    display: flex;
    justify-content: space-around;
  }
  .button_allocation {
    width: 134px;
    height: 30px;
    background: url("../../../assets/bgm/buttonBlue.png");
    background-size: cover;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6591f1;
    line-height: 28px;
    cursor: pointer;
  }
  .button_compile {
    width: 65px;
    height: 30px;
    background: url("../../../assets/bgm/smallBlue.png");
    background-size: cover;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6591f1;
    line-height: 28px;
    cursor: pointer;
  }
  .button_delete {
    width: 62px;
    height: 28px;
    background: url("../../../assets/bgm/red.png");
    background-size: cover;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #e84b4d;
    line-height: 28px;
    cursor: pointer;
  }
  .title {
    display: flex;
    border-bottom: 1px solid #ececec;
    height: 40px;
    p:first-child {
      width: 3px;
      height: 13px;
      background: #6591f1;
      margin-top: 5px;
    }
    p:last-child {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-left: 10px;
    }
  }
  /deep/.el-input__inner {
    background-color: #f7f8fa;
    color: #999999;
    border: none;
  }
  .line {
    border-bottom: 1px solid #ececec;
    margin: 20px 0px;
  }
  .table_flex {
    display: flex;
    justify-content: space-between;
  }
  .table_text {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    // line-height: 20px;
    margin-bottom: 10px;
  }
}
</style>
